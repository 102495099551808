<script setup lang="ts">
import type { PropType } from 'vue'
import type { ButtonSize } from '#ui/types'

const props = defineProps({
	size: {
		type: String as PropType<ButtonSize>,
		default: 'lg'
	},
	icon: {
		type: String,
		default: 'i-app-document-plus'
	},
	iconClass: {
		type: String,
		default: 'hidden md:block md:mt-0 md:w-5 md:h-5 xl:w-4 xl:h-4'
	},
	textClass: {
		type: String,
		default: 'md:w-min block md:hidden xl:block text-xs xl:text-xs !leading-tight'
	},
	onModal: {
		type: Function,
		default: () => ({})
	}
})

const { t } = useI18n({
	useScope: 'local'
})

const authStore = useAuthStore()
const router = useRouter()

const isOpen = ref(false)

const onOpen = () => {
	isOpen.value = true
}

const onCreate = () => {
	authStore.isLogged
		? onAuth()
		: onOpen()
}

const onAuth = async () => {
	isOpen.value = false
	props.onModal()
	
	await router.push('/cabinet/ads/create')
}

watch(isOpen, (newVal: boolean) => {
	if (!newVal) {
		props.onModal()
	}
})

</script>

<template>
	<UButton
		v-bind="$attrs"
		color="amber"
		:size="size"
		class="transition flex gap-0 items-center justify-center px-0"
		dusk="ad-button"
		:aria-label="t('button.create')"
		@click="onCreate"
	>
		<div class="flex items-center justify-center mr-2 md:mr-0 xl:mr-1">
			<UIcon
				:name="icon"
				:class="iconClass"
			/>
		</div>
		<div :class="textClass">
			{{ t('button.create') }}
		</div>
	</UButton>
	<UiModal
		v-model="isOpen"
		:fullscreen="$isMobile() && !$isTablet()"
	>
		<AuthForm
			:title="t('auth.title')"
			:callback="onAuth"
		/>
	</UiModal>
</template>

<i18n lang="json">
{
	"ru": {
		"button": {
			"create": "Разместить объявление"
		},
		"auth": {
			"title": "Размещение объявления"
		}
	}
}
</i18n>